import Logo from '../img/g91.png'

const Footer = () => {
  return (
    <div className='logo'>
      <a href='https://vyhivskyi.com'>
        <img src={Logo} alt='[ vyhivskyi ]' />
      </a>
    </div>
  )
}

export default Footer
