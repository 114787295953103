const Auth = ({ user, handleSignOut, dataSave }) => {
  // console.log(user)
  // sub - The unique ID of the user's Google Account

  dataSave({
    name: user.name,
    picture: user.picture,
    action: 'start',
  })

  return (
    <div id="divAuth">
      <img src={user.picture} alt={user.name} />
      <h2>{user.name}</h2>
      <div>
        <button onClick={(e) => handleSignOut(e)}>Вийти</button>
        <p>
          *це тестова авторизація, ми не збираємо та не реєструємо данні
          користувачів!
        </p>
      </div>
    </div>
  )
}

export default Auth

/**
 * https://developers.google.com/identity/gsi/web/reference/js-reference?hl=ru#shape
 * header
{
  "alg": "RS256",
  "kid": "f05415b13acb9590f70df862765c655f5a7a019e", // JWT signature
  "typ": "JWT"
}
payload
{
  "iss": "https://accounts.google.com", // The JWT's issuer
  "nbf":  161803398874,
  "aud": "314159265-pi.apps.googleusercontent.com", // Your server's client ID
  "sub": "3141592653589793238", // The unique ID of the user's Google Account
  "hd": "gmail.com", // If present, the host domain of the user's GSuite email address
  "email": "elisa.g.beckett@gmail.com", // The user's email address
  "email_verified": true, // true, if Google has verified the email address
  "azp": "314159265-pi.apps.googleusercontent.com",
  "name": "Elisa Beckett",
                            // If present, a URL to user's profile picture
  "picture": "https://lh3.googleusercontent.com/a-/e2718281828459045235360uler",
  "given_name": "Elisa",
  "family_name": "Beckett",
  "iat": 1596474000, // Unix timestamp of the assertion's creation time
  "exp": 1596477600, // Unix timestamp of the assertion's expiration time
  "jti": "abc161803398874def"
}
 */
