import { useEffect, useState, useRef } from 'react'
// Встановили бібліотеку jwt-decode, що декодувати токен в об'єкт
import jwtDecode from 'jwt-decode'
import Auth from './Auth'

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    // script.async = true
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

// Тестування выдправки даних у форматі JSON
async function dataSave(data) {
  const url = 'https://pr2.vyhivskyi.site/api.php'

  try {
    const response = await fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    // Маємо об'єкт json який отримали з серверу, з api.php
    const json = await response.json()
    // console.log(JSON.stringify(json))
  } catch (error) {
    // Маємо помилку
    console.error('Error dataSave function:', error)
  }
}

const GoogleAuth = () => {
  const [user, setUser] = useState({})
  const googleButton = useRef(null)

  // local
  // const API_KEY =
  //   '4944555133-kpc3ktvutf5svtsfukmmevgdbo5ue95i.apps.googleusercontent.com'

  // public
  const API_KEY =
    '4944555133-ak5hvn70b6m3l4sa0om3srgb24k8rrv9.apps.googleusercontent.com'

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client?hl=uk'
    const id = API_KEY

    loadScript(src)
      .then(() => {
        /*global google*/
        // console.log(google)
        window.google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        })
        window.google.accounts.id.renderButton(googleButton.current, {
          theme: 'outline',
          size: 'large',
        })
        // Якщо потрібно відображати додаткові кнопки входу одним кліком
        window.google.accounts.id.prompt()
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  function handleCredentialResponse(response) {
    // console.log('Encoded JWT ID token: ' + response.credential)
    let userObject = jwtDecode(response.credential)
    // console.log(userObject)
    document.getElementById('google-btn-box').hidden = true // приховати кнопку входу
    setUser(userObject)
  }

  function handleSignOut(event) {
    // Якщо користувач хоче вийти, то ми просто передамо пустий об'єкт користувача
    // ну і показати кнопку входу
    setUser({})
    document.getElementById('google-btn-box').hidden = false
  }

  return (
    <>
      <div id="google-btn-box" ref={googleButton}></div>
      {Object.keys(user).length !== 0 && (
        <Auth user={user} handleSignOut={handleSignOut} dataSave={dataSave} />
      )}
    </>
  )
}

export default GoogleAuth
