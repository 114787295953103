import { useCallback } from 'react'
import GoogleAuth from './components/GoogleAuth'
import Particles from 'react-particles'
import { loadFull } from 'tsparticles'
import particlesOptions from './data/particles.json'
import Footer from './components/Footer'

// https://stackoverflow.com/questions/72209749/react-google-identity-services

function App() {
  // PARTICLES EFFECTS
  const particlesInit = useCallback(async (engine) => {
    // console.log(engine)
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine)
  }, [])

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container)
  }, [])

  return (
    <div className='App'>
      {/* Particles */}
      <Particles
        id='tsparticles'
        // Вказати параметри в файлі particles.json локально або відділено через url
        // url="http://foo.bar/particles.json"
        options={particlesOptions}
        init={particlesInit}
        loaded={particlesLoaded}
      />
      <GoogleAuth />
      <Footer />
    </div>
  )
}

export default App
